<template>
  <div class="pkmn-list pkmn-list--costcenters">
    <v-row
      class="list__row"
      dense
      v-for="item in data"
      :key="item.id"
    >
      <v-col cols="10">
        <h3 class="vehicle__plate-num">{{ item.cost_center }}</h3>
      </v-col>
      <v-col v-if="!disabled" class="text-right visible-on-hover" cols="2">
        <img
          class="list__button-trigger"
          src="@/assets/button-delete.svg"
          @click="deleteVehicle(item)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CostCenterList',
  props: ['disabled', 'data'],
  data() {
    return {
      selectedItem: null
    }
  },
  methods: {
    deleteVehicle(record) {
      this.$emit('delete', record)
    }
  }
}
</script>

<style lang="scss" scoped>
.pkmn-list.pkmn-list--costcenters {
  .list__row {
    padding: 4px 0 4px 12px;

    div[class*='col'] {
      align-self: center;
    }
  }

  .list__delete-button {
    margin-right: 8px;
  }

  .list__button-trigger {
    cursor: pointer;
    display: inline-block;
    transform: translateX(-16px) translateY(2px);
  }
}
</style>
