<template>
  <div class="page page--employees">
    <v-row>
      <v-col>
        <h1 class="cost_center_title">
            {{ $t('mandatory_comment_title') }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
      <p class="cost_center__desciptions">
        {{ $t('mandatory_comment_description') }}
      </p>
      </v-col>
      <v-col cols="1" 
      align="right">
      <v-switch
            v-model="isMandatoryComment"
            hide-details
            @change="updateToggle"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <h1 class="cost_center_title_second">
          {{ $t('cost_centers_title') }}
        </h1>
        <p class="cost_center__desciptions">
          {{ $t('cost_center_description') }}
            </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
      <cost-centers-form
          ref="form"
          @submit="addCostCenter"
          :list="costCenters"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <cost-center-list
          :data="costCenters"
          @delete="deleteCostCenter"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'
import CostCenterList from '@/components/CostCenters/List'
import CostCentersForm from '@/components/CostCenters/Form'

export default {
  name: 'CostCentersView',
  components: { CostCenterList, CostCentersForm },
  data() {
    return {
      auth: auth,
      isMandatoryComment: false,
      costCenters: [],
      openDeleteConfirmDialog: false
    }
  },
  computed: {
  },
  methods: {
    async addCostCenter(item) {
      const company = this.$store.state.companyData
      if (!company) { return }
      const costCenter = await userHelpers.createCostCenters(company.id, {cost_center: item})
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('cost_center_added', {
          cost_center_name: `"${item}"`
        })
      })
      this.costCenters.push(costCenter)
    },
    async getCostCenters() {
      let company = this.$store.state.companyData
      if (!company) {
        await helpers.fetchDashboardData()
        company = this.$store.state.companyData
      }
      const response = await userHelpers.fetchCostCenters(company.id)
      this.isMandatoryComment = response.mandatory_comment_required
      this.costCenters = response.cost_centers
    },
    async deleteCostCenter(item) {
      const company = this.$store.state.companyData
      if (!company) { return }
      await userHelpers.deleteCostCenters(company.id, item.id)
      await this.getCostCenters()
    },
    async updateToggle(state) {
      const company = this.$store.state.companyData
      if (company) {
        await userHelpers.updateMandatoryCommentToggle(company.id, {value: state ? 1 : 0 })
      }
    }
  },
  async mounted() {
    await this.getCostCenters()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.cost_center_title,
.cost_center_title_second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

/* Unique style for .cost_center_title */
.cost_center_title {
  padding-top: 40px;
}
.cost_center__desciptions {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; 
      opacity: 0.8;
}

.switch-left .v-input__slot {
  flex-direction: row-reverse;
}
.cost_center__list-toggle {
  .v-input__slot {
    flex-direction: row-reverse;
  }

  .v-label {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; 
      opacity: 0.8;
  }
}
</style>
