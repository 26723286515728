<template>
  <v-form ref="form" class="pkmn-form pkmn-form--car" lazy-validation>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          ref="newCarInput"
          v-model="newCostCenter"
          class="pkmn-input pkmn-input--add"
          :label="$t('cost_center_input_field_hint')"
          append-icon="mdi-plus-circle"
          :rules="[rules.noDuplication]"
          @click:append="addCostCenter"
          @keydown="onNewCostCenterInputKeydown"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row dense v-if="errorMessage">
      <v-col cols="12" class="text-right">
        <p class="pkmn-form__error" v-html="errorMessage"></p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import api from '@/helpers/api'
import rules from '@/helpers/validation'

export default {
  name: 'CostCentersForm',
  props: ['list'],
  data() {
    return {
      newCostCenter: '',
      rules: {
        ...rules,
        noDuplication: (value) =>
          !this.isCostCenterDuplicated(value) ||
          this.$t('error_existed', { object: this.$t('g_car') })
      },
      errorMessage: null
    }
  },
  methods: {
    /**
     * Prevent default enter event on new car input box
     * Trigger add car action when Enter is pressed
     */
    onNewCostCenterInputKeydown(event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        this.addCostCenter()
      }
    },
    /**
     * Check car if it is in the list
     * ABC-123, abc-123, ABC123 & ABC 123 are considered duplicated
     * @returns {Boolean} true if car is in the list, false otherwise
     */
    isCostCenterDuplicated(costCenter) {

      return (
        this.list.findIndex((item) => {
          const specialCharRegex = /[- ]/g
          return (
            item.cost_center.replace(specialCharRegex, '').toLowerCase() ===
            costCenter.replace(specialCharRegex, '').toLowerCase()
          )
        }) > -1
      )
    },
    async addCostCenter() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('submit', this.newCostCenter)
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss">
.pkmn-form--car .pkmn-input--add .v-icon {
  margin-right: 18px;
  margin-bottom: 4px;
}
</style>
